import React from "react";


const NotFoundPage = () => {
  return (
    <div className="container_notFoundPage">
      <div className="content_notFoundPage">
        <div className="text_notFoundPage">
          {/* <h3>404 Error</h3> */}
          <h1>Uh-oh, Lost Your Way?...</h1>
          <p>
            It looks like you've hit a wrong turn, but no worries, it happens to
            the best of us! Our team is on it, working to get you back on track
            and help you find what you're looking for.
          </p>
        </div>
        <div className="buttons_notFoundPage">
          <a className="home_button" href="/">
            Back To Home Page
          </a>
          <a href="/ticketform">Contact Us</a>
          <a href="/ticketform">Get Tickets</a>
          <a href="/sponsorform">Sponsor Registration</a>
        </div>
      </div>
      <div className="image_notFoundPage">
        <img src="./images/404.svg" alt="404 Error Display" />
      </div>
    </div>
  );
};

export default NotFoundPage;
